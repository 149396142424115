import p1 from "../assets/process/p1.JPG";
import p2 from "../assets/process/p2.JPG";
import p3 from "../assets/process/p3.JPG";
import p4 from "../assets/process/p4.JPG";
import p5 from "../assets/process/p5.jpg";
import p6 from "../assets/process/p6.jpg";
import p7 from "../assets/process/p7.JPG";
import p8 from "../assets/process/p8.JPG";
import p9 from "../assets/process/p9.jpg";
import p10 from "../assets/process/p10.JPG";
import p11 from "../assets/process/p11.JPG";
import p12 from "../assets/process/p12.jpg";

import p13 from "../assets/process/p13.jpg";
import p14 from "../assets/process/p14.jpg";
import p15 from "../assets/process/p15.jpg";
import p16 from "../assets/process/p16.jpg";
import p17 from "../assets/process/p17.jpg";
import p18 from "../assets/process/p18.jpg";

import p19 from "../assets/process/p19.jpg";
import p20 from "../assets/process/p20.jpg";

const ImagesProcess = {
  // s1
  p1,
  p2,
  p3,
  p4,

  // CHALLENGES AND DIFFICULTIES IN PURSUING PASSION
  p5,
  p6,

  //My Mother’s Embrace on My Musical Journey
  p7,
  p8,
  p9,
  // teacheer
  p10,
  p11,
  p12,

  // Performer
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20
};

export default ImagesProcess;
