import about1 from "../assets/about/about2.JPG";
import about2 from "../assets/about/about3.JPG";
import about3 from "../assets/about/about5.JPG";
import about4 from "../assets/about/about4.jpg";
import about5 from "../assets/about/about1.JPG";

//2 chi e
import about6 from "../assets/about/about7.JPG";
import about7 from "../assets/about/about6.JPG";

import about9 from "../assets/about/about9.jpg";

import about10 from "../assets/about/about10.jpg";
import about11 from "../assets/about/about11.jpg";
import about12 from "../assets/about/about12.jpg";
import about13 from "../assets/about/about13.jpg";

const ImagesAbouts = {
  // s1
  about1,
  about2,
  about3,
  about4,
  about5,
  // s2
  about6,
  about7,
  // ong ba
  about9,
  about10,
  about11,
  about12,
  about13,
};

export default ImagesAbouts;
