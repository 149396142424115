import { Carousel, Col, Image, Layout, Row, Typography } from "antd";
import { motion } from "framer-motion";
import React from "react";
import ImagesAchievement from "../images/ImagesAchievement.js";
import ImagesAchievement2019 from "../images/ImagesAchievement2019.js";
import ImagesAchievementKorea from "../images/ImagesAchievementKorea.js";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const linkVideoID = [
  {
    key: 1,
    title: "Kuhlau Sonatina Op. 60 No. 1 - Allegro moderato",
    value: "XlvdVQsowtk",
  },
  {
    key: 2,
    title: "Burgmuller Etude Les Sylphes by Tri Quang (8 years old)",
    value: "31oh7A0eUbY",
  },
  {
    key: 3,
    title: "Kabalevsky 'Gray Day' Variations in D Minor, Op. 51, No. 3",
    value: "YdOpt1y7KX8",
  },

  {
    key: 4,
    title: "Nguyễn Trí Quang biểu diễn tại Tây Ban Nha",
    value: "G2VugJpR-qI",
  },

  {
    key: 5,
    title: "Nguyễn Trí Quang biểu diễn tại Tây Ban Nha 2017",
    value: "JQU6-70ZNog",
  },
  {
    key: 6,
    title: "Tri Quang Nguyen Biểu diễn tại Tây Ban Nha 2017",
    value: "4jT7ASFXdv8",
  },
  {
    key: 7,
    title: "Tri Quang biểu diễn tại Bồ Đào Nha",
    value: "sn-hgBlkuIg",
  },
  {
    key: 8,
    title: "Quang, Tri Nguyen/Dieu Quang Truong Duo (15)",
    value: "XEZIzJoxBM0",
  },
  {
    key: 9,
    title: "Tri Quang - Piano Practice",
    value: "3mEW7rwcmaw",
  },
  {
    key: 10,
    title: "Nguyen Tri Quang - Piano 01",
    value: "S0dtT2vTNgQ",
  },
  {
    key: 11,
    title: "Tri Quang - Daily practice",
    value: "Wjt1csA3JPc",
  },
  {
    key: 12,
    title: "Nguyen Trí Quang - Daily Practice",
    value: "B22afb6zku0",
  },
];

const Achievements = () => {
  const imageList = Object.values(ImagesAchievement);

  const selectedImages1 = imageList.slice(19, 24);

  const ImageList2019 = Object.values(ImagesAchievement2019);

  const ImageList2019P1 = ImageList2019.slice(0, 5);



  const ImageList2019P2 = ImageList2019.slice(5, 11);

  const ImageListKorea = Object.values(ImagesAchievementKorea);

  return (
    <Layout
      style={{
        height: "100%",
        background: "none",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Content style={{ padding: "40px 0px", overflow: "hidden" }}>
        <Row gutter={[50, 50]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <motion.span
              className="custom-header"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <b>CHAPTER 2: ACHIEVEMENTS</b>
            </motion.span>
          </Col>
        </Row>
        <br />
        {/* Symphony Of Victory  START  */}
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">Symphony Of Victory</div>
                <Paragraph>
                  Throughout my journey of studying and practicing the piano, I
                  have had the opportunity to participate in many domestic and
                  international music competitions, achieving impressive
                  results. These awards are not only the result of my efforts
                  but also a testament to my deep passion for music and the
                  dedicated guidance of teachers who have supported my
                  continuous growth.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        {/* Symphony Of Victory  START  */}

        <br />
        {/* 2015: Third Prize Winner, Category A, Vietnam National Academy of Music Piano Festival, 2015 */}
        <Row gutter={[50, 20]} justify="center">
          {/* Cột cho Văn bản 2015 */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">2015</div>
                <motion.div
                  style={{
                    backgroundColor: "#8B0000",
                    height: "2px",
                    width: "100%",
                    originX: 0,
                  }}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Third Prize Winner, Category A, Vietnam National Academy of
                  Music Piano Festival, 2015
                </div>
                <Paragraph>
                  At the age of 8, I participated in the Vietnam National
                  Academy of Music Piano Festival and won a Third Prize in
                  Category A. In this competition, I performed two pieces:
                  Kuhlau Sonatina Op. 88 No. 2: Rondo in G major and Kuhlau
                  Sonatina Op. 60 No. 1 Allegro in D major. I vividly remember
                  the applause echoing throughout the hall after my performance.
                  For an 8-year-old, it was a strange and overwhelming feeling.
                  That applause filled me with pride, but also nervous
                  excitement. My heart raced, and the clapping felt like
                  encouragement. At that moment, I was deeply grateful to share
                  my music with the audience — a moment I will never forget.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[0].value}`}
                  title={linkVideoID[0].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[1].value}`}
                  title={linkVideoID[2].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[2].value}`}
                  title={linkVideoID[2].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Gold Prize, Asia International Piano Festival and
                  Competition in Seoul 2015
                </div>
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  I feel fortunate to have been exposed to international awards
                  at an early age. In 2015, my sister and I went to South Korea
                  to participate in Asia International Piano Festival and
                  Competition in Seoul. I was 8 years old when I was awarded the
                  Gold Prize – the first international certificate in my music
                  life.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        {/* 2015: Third Prize Winner, Category A, Vietnam National Academy of Music Piano Festival, 2015  */}

        <br />
        {/* 2017: Competitions in Spain and France
         */}
        {/* Cột cho Văn bản */}
        <Row gutter={[50, 20]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">2017</div>
                <motion.div
                  style={{
                    backgroundColor: "#8B0000",
                    height: "2px",
                    width: "100%",
                    originX: 0,
                  }}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  Competitions in Spain and France
                </div>
                <Paragraph>
                  The year 2017 marked a significant milestone in my musical
                  journey, as a 10-year-old boy performing on major stages in
                  Spain and France for the first time.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • First Prize Winner, Junior Category, Concours International
                  de Musique Opus Artis Paris 2017
                </div>
              </motion.div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[0]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[1]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Second Prize Winner, Group 2, 28th FLAME Concours 2017
                </div>
              </motion.div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[2]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[3]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Third Prize Winner, Junior Category, Campus Nacional de
                  Musica y Danza “Villa de el Tiemblo” 2017
                </div>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[3].value}`}
                  title={linkVideoID[3].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[4].value}`}
                  title={linkVideoID[4].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[5].value}`}
                  title={linkVideoID[5].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Recipient of Toyota Scholarship for Young Vietnamese Music
                  Talents (2017)
                </div>
              </motion.div>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[4]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[5]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[6]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        {/* 2017: Competitions in Spain and France
         */}
        <br />
        <br />

        {/* 2019
         */}
        {/* Cột cho Văn bản */}
        <Row gutter={[50, 20]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">2019</div>
                <motion.div
                  style={{
                    backgroundColor: "#8B0000",
                    height: "2px",
                    width: "100%",
                    originX: 0,
                  }}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • First Prize Winner, Category C, Santa Cecilia Piano
                  International Competition 2019
                </div>

                <Paragraph>
                  The piano competition in Portugal was an unforgettable
                  experience. In this competition, besides Chopin’s beautiful
                  piece of music, I performed another piece that featured the
                  folk melodies of the rivers and streams of the Mekong Delta.
                  This was a piece my mother and I often listened to during
                  evening naps back home. As sitting at the piano on the stage,
                  I didn’t think much; I just wanted to perform naturally,
                  playing the folk tunes in the same way I had always played
                  them. The folk melody flowed, carrying with it the nostalgic
                  emotions of the people from my homeland, and I was deeply
                  moved every time I heard those familiar notes.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[6].value}`}
                  title={linkVideoID[6].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  When the judges began announcing the results, my heart raced
                  but I tried to stay calm. I sat there, hearing each name
                  called out for prizes: the third, the second prize….I still
                  did not hear my name…
                </Paragraph>
                <Paragraph>
                  Then, when the first prize was announced, the voice slowly
                  called my name, and the entire hall paused for a moment. I
                  couldn’t believe what I was hearing. I looked around and felt
                  my mother’s hand on my shoulder - her eyes wide with surprise
                  and full of pride. As the announcer called my name one more
                  time, the audience erupted in applause. I felt like it was all
                  a dream.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <Row gutter={[50, 20]} align="middle">
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[30]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[31]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[32]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  I stood there, my legs trembling, feeling like I was walking
                  in a dream. Each step I took towards the stage was filled with
                  overwhelming emotions: confusion, surprise, but also a hint of
                  pride. I never imagined I would win the highest prize at this
                  international competition. When I received the trophy from the
                  judges, the warmth in my hands spread throughout my body. At
                  that moment, I realized that all my efforts over time had led
                  to a well-deserved result. My mother, sitting in the audience,
                  was so moved that she was teary-eyed when she heard my name
                  called. She had given me all her support, and when I shared
                  that moment of pride with her, it was the most precious
                  feeling. At that moment, I realized that all the hard work and
                  dedication resulted in an outcome beyond my imagination
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        {/* các ảnh còn lại */}
        <Row gutter={[50, 50]} justify="center">
          {ImageList2019P1.map((el, index) => (
            <Col key={index} span={4}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Image
                  src={el}
                  alt={`Gallery image + el`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </motion.div>
            </Col>
          ))}
        </Row>
        <br />
        <Row gutter={[50, 20]} justify="center">
          {ImageList2019P2.map((el, index) => (
            <Col key={index} span={4}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Image
                  src={el}
                  alt={`Gallery image + el`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </motion.div>
            </Col>
          ))}
        </Row>
        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  The judges’ comments after my performance came as an
                  unexpected surprise. They praised me for my great potential
                  and genuine musicality, encouraging me to pursue a
                  professional path early on. Hearing those words, my happiness
                  soared, and I realized how important it is to believe in
                  myself and how far I could go.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <Row gutter={[50, 50]} justify="space-around" align="middle">
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[8]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[10]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[9]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        <br />
        <br />
        {/* Fall 2019 */}
        <Row gutter={[50, 20]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Encouraging Prize, Category A, Hanoi International Piano
                  Competition – Fall 2019
                </div>
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">2023</div>
                <motion.div
                  style={{
                    backgroundColor: "#8B0000",
                    height: "2px",
                    width: "100%",
                    originX: 0,
                  }}
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • First Prize Winner, Section Piano - Duo XV Category, XI The
                  North International Music Competition, Stockholm, Sweden
                </div>
              </motion.div>
            </div>
          </Col>
          {/* ảnh */}
          <Col span={24} md={8}>
            <Image
              src={ImageList2019[12]}
              alt={`Achievement + 12}`}
              style={{
                width: "100%",
                height: "400px",
                objectFit: "cover",
              }}
              preview={false}
            />
          </Col>
          <Col span={24} md={16}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[7].value}`}
                  title={linkVideoID[7].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Gold Prize Winner, Category B, Asia International Piano
                  Academy Festival and Competition in Cheonan, South Korea
                </div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Row gutter={[16, 16]}>
              {ImageListKorea.map((src, index) => (
                <Col key={index} xs={12} sm={8} md={6} lg={4} xl={4}>
                  <Image
                    src={src}
                    alt={`Achievement ${index + 12}`}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    preview={false}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <br />
        <Row gutter={[50, 10]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Second prize winner, Section Piano - Duet performance in the
                  2024 Winter Music Competition, Charleston International Music
                  Competition
                </div>
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xVYIYUR-dtU?si=BQXzweErVT7COwiu"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </motion.div>
            </div>
          </Col>
        </Row>

        <br />
        <br />
        <br />

        <Row justify="space-around" align="middle">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  • Finalist of the 2024 Chicago International Music Competition
                </div>
              </motion.div>
            </div>
          </Col>
          {selectedImages1.map((src, index) => (
            <Col
              key={index}
              xs={8} // 3 cột trên màn hình nhỏ hơn md
              sm={8}
              md={4} // 6 cột trên màn hình từ md trở lên
              lg={4}
              xl={4}
            >
              <Image
                src={src}
                alt={`Achievement ${index + 12}`}
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
            </Col>
          ))}
        </Row>
        {/* Gom CC lẻ  */}
        <Row gutter={(20, 16)}>
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                paddingTop: "20px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">• Others </div>
              </motion.div>
            </div>
          </Col>

          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                padding: "16px",
                textAlign: "center",
                height: "350px",
              }}
            >
              <Image
                src={imageList[24]}
                alt={imageList[24]}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <span>
                First prize winner, Section Piano - Concerto in the 2024 UK
                International Music Competition (Season 2)
              </span>
            </motion.div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                padding: "16px",
                textAlign: "center",
                height: "350px",
              }}
            >
              <Image
                src={imageList[25]}
                alt={imageList[26]}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <span>
                First prize winner, Section Piano in the 2024 Gdansk 9th
                International Piano Competition (Poland)
              </span>
            </motion.div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                padding: "16px",
                textAlign: "center",
                height: "350px",
              }}
            >
              <Image
                src={imageList[18]}
                alt={imageList[18]}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <span>
                First prize winner, Section Piano – Solo performance in the 2024
                Spring Music Competition. Charleston International Music
                Competition
              </span>
            </motion.div>
          </Col>
        </Row>

        <br />
        {/* Gom CC lẻ  */}
        <br />
        {/*  Scholarship for Hotchkiss Summer Program*/}
        {/* Cột cho Văn bản */}
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">
                  Scholarship For Hotchkiss Summer Program
                </div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="center" align="middle">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  In July 2024, I was honored to receive a scholarship to
                  participate in the Hotchkiss Summer Program in Music, held at
                  The Hotchkiss School, Lakeville, Connecticut, USA. This is one
                  of the prestigious music programs that attracts many talents
                  from around the world. During the program, I had the
                  opportunity to visit several leading universities in the U.S.,
                  including New York University, Yale University, Boston
                  University, Northeastern University, Boston College, and Tufts
                  University. Through these visits, I learned about the culture,
                  architecture, and unique academic environments of each school,
                  which will help guide my future study choices.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[26]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>

          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[27]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  In addition to academic activities, I participated in sports
                  such as running and basketball, which helped me build new
                  relationships with my teachers and international students.
                  These activities not only improved my physical fitness and
                  dynamism but also enabled me to connect and learn from friends
                  from different countries.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  In terms of music, I had the opportunity to attend in-depth
                  piano lessons under the guidance of Professor Rachel Miller, a
                  renowned instructor. The lessons focused on performance
                  skills, emotional control, and improving my musical
                  expression. The highlight of the program was when I was
                  selected to perform a solo piece by Brahms at Carnegie Hall in
                  New York, one of the world’s most prestigious concert venues.
                  This public performance was a milestone in my musical journey,
                  helping me gain valuable performance experience and
                  solidifying my international relationships in the music
                  community.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[28]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        <br />
        <br />
        {/*  Scholarship for Hotchkiss Summer Program
         */}
        {/* Cột cho Văn bản */}
        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">Award Experience</div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        <br />

        <Row gutter={[50, 50]} justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">
                  Passion Is The Flame That Ignites All Efforts
                </div>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  My love for the piano drives me to constantly seek beauty in
                  music. I spend hours listening to the masterpieces of great
                  artists like Chopin, Beethoven, Liszt, and Rachmaninoff. Not
                  only do I feel their emotions through every note, but I also
                  learn how they express those feelings in their music. I don’t
                  just listen to one piece, but many different styles and
                  genres, from Baroque to Romantic, from classical to
                  contemporary. I don’t listen merely for enjoyment but to
                  immerse myself in each rhythm, color, and subtle moment of the
                  music.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[8].value}`}
                  title={linkVideoID[8].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[9].value}`}
                  title={linkVideoID[9].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  This love for the piano has helped me realize the deep
                  connection between myself and music, making daily practice
                  more than just routine but a journey of self-discovery. When I
                  dive into the world of great works, I not only learn how to
                  play the notes but also how to understand and live through the
                  music, so that each performance becomes not just a recital but
                  a soulful conversation with the audience.{" "}
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  In the competitive world of piano contests, this passion
                  becomes my motivation, helping me dedicate hours to practice
                  every day, constantly improving through listening and learning
                  from the masters.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[10].value}`}
                  title={linkVideoID[10].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>

        {/* Cột cho Văn bản */}
        <br />
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">
                  Daily Practice Is The Key Element For Achieving Success
                </div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />

        <Row gutter={[50, 50]} justify="center" align="middle">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Every day as you sit at the piano, letting your fingers glide
                  over the keys, it’s not just about playing the right notes or
                  perfecting the technique. It’s about hearing the sounds rise
                  and deeply feeling every melody. Practice isn’t just about
                  repetition; it’s about discovering the essence of each piece,
                  from the soft, delicate notes to the strong, decisive
                  transitions of complex passages.
                </Paragraph>
                <Paragraph>
                  Diligence is the key that unlocks the door to artistic
                  mastery, but this journey is never easy. There are moments
                  when I face difficult sections of music, my fingers grow
                  tired, and at times, I feel discouraged. Yet perseverance
                  helps me push through these obstacles. Every time I overcome a
                  challenging passage, it feels like climbing a new mountain,
                  and that becomes the joy of my practice journey.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[11].value}`}
                  title={linkVideoID[11].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Each musical piece, whether simple or complex, has its own
                  story, and it’s through perseverance that I can fully
                  understand the emotions the composer intended to convey.
                  Practice is not only a physical exercise but also a spiritual
                  journey, helping me to not only improve my technical skills
                  but also to deeply grasp the emotional expression of the
                  music.
                </Paragraph>
                <Paragraph>
                  Persistent practice not only helps me succeed in competitions
                  but also aids in my growth within the world of music.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[29]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        <br />

        {/* Cột cho Văn bản */}
        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">Daily Music Listening</div>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Listening to music daily is like planting seeds of musical
                  emotions in your soul, and over time, these seeds grow and
                  bloom into a vibrant garden. Listening to the works of
                  renowned pianists not only helps me gain more musical
                  knowledge but also opens a broader world of emotional
                  perception and a deeper understanding of each musical piece.
                </Paragraph>
                <Paragraph>
                  Daily music listening is like building a “bank of emotions,”
                  where I store not only beautiful musical moments but also the
                  ways to express them most effectively. This has become a
                  source of inspiration every time I sit down at the piano,
                  allowing me to not just play a piece but immerse myself in its
                  emotional flow and live through each note, each moment.
                </Paragraph>
                <Paragraph>
                  Listening to music every day helps me improve my focus and
                  sensitivity to sound perception. It’s like a regular practice
                  of musical awareness, enabling me to express not just the
                  notes but the very soul of the music. This practice has become
                  a wellspring of creativity and exploration, allowing me to
                  discover new ways to express myself through the piano, and
                  every day brings new insights into how music connects with
                  emotions and experiences I’ve accumulated over time.
                </Paragraph>
                <Paragraph>
                  Each day of listening to music teaches me something new, not
                  only about the works of famous composers but also about modern
                  music. It’s an ongoing journey of searching for deeper
                  emotional connections with the world of music. Through daily
                  listening and reflection, I understand myself better,as well
                  as how music can connect from heart to heart.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Achievements;
