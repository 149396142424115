import React from "react";
import { NavLink } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import "./Header.css";

const items = [
  {
    key: "1",
    label: (
      <NavLink to="/process" className="nav__link" activeClassName="active">
        DEVELOPMENT PROCESS
      </NavLink>
    ),
  },
  {
    key: "2",
    label: (
      <NavLink
        to="/achievements"
        className="nav__link"
        activeClassName="active"
      >
        ACHIEVEMENTS
      </NavLink>
    ),
  },
];
const Header = () => {
  return (
    <header className="header">
      <nav className="nav container">
        <a href="/" className="nav__logo ff-adobe">
          <b>TRI QUANG NGUYEN</b>
        </a>

        <div className={`nav__menu`} id="nav-menu">
          {/* desktop */}
          <ul className="nav__list ff-avenir">
            <li className="nav__item">
              <NavLink to="/process" className="nav__link">
                DEVELOPMENT PROCESS
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/achievements" className="nav__link">
                ACHIEVEMENTS
              </NavLink>
            </li>
          </ul>

          {/* mobile */}
          <div className="nav__list_mobile">
            <Dropdown menu={{ items }} placement="bottomRight">
              <MenuOutlined style={{ fontSize: "24px", color: "#FFF" }} />
            </Dropdown>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
