import p1 from "../assets/achievement/a34.JPG";
import p2 from "../assets/achievement/a35.JPG";
import p3 from "../assets/achievement/a36.JPG";
import p4 from "../assets/achievement/a37.JPG";
import p5 from "../assets/achievement/a44.JPG";
import p6 from "../assets/achievement/a39.JPG";
import p7 from "../assets/achievement/a40.JPG";
import p8 from "../assets/achievement/a38.JPG";
import p9 from "../assets/achievement/a41.JPG";
import p10 from "../assets/achievement/a42.JPG";
import p11 from "../assets/achievement/a43.JPG";
import p12 from "../assets/achievement/a38.JPG";

// next
import p13 from "../assets/achievement/a45.jpg";

// các ảnh còn lại
const ImagesAchievement2019 = {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
};
export default ImagesAchievement2019;
