import { Button, Col, Image, Layout, Row, Typography } from "antd";
import { motion } from "framer-motion";
import React from "react";
import ImagesAbouts from "../images/ImagesAbouts.js";
import { NavLink } from "react-router-dom";

import "./Process.css";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const linkVideoID = [
  {
    key: 1,
    title: "Spontaneity on the Piano Keys…",
    value: "WNcsUNKlAKw",
  },
];

const Home = () => {
  const imageList = Object.values(ImagesAbouts);

  const imageS1 = imageList.slice(0, 3);
  const imageS2 = imageList.slice(3, 5);
  const imageS3 = imageList.slice(5, 7);

  return (
    <Layout
      style={{
        height: "100%",
        background: "none",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Content style={{ padding: "40px 0px" }} className="process">
        <Row gutter={[50, 50]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <motion.span
              className="custom-header"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <b> ABOUT ME</b>
            </motion.span>
          </Col>
        </Row>
        <br />

        {/* s1 */}
        {/* ảnh */}
        <Row gutter={[50, 50]} justify="center">
          {imageS1.map((imgSrc, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={8}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Image
                  src={imgSrc}
                  alt={`Gallery image ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />

                {index === 1 && (
                  <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Paragraph>
                      I am a fortunate young man because I was born and raised
                      in a family with a tradition of academic excellence. My
                      grandparents, parents, and sister have all achieved
                      significant success in the fields they pursued. However,
                      my parents never imposed any expectations or set a
                      predetermined path for me; they always created the
                      conditions for me to explore and grow naturally.
                    </Paragraph>
                  </motion.div>
                )}
              </motion.div>
            </Col>
          ))}
        </Row>
        <br />

        {/* s2 */}
        <Row gutter={[50, 50]} justify="center">
          {imageS2.map((imgSrc, index) => (
            <Col key={index} xs={24} sm={12} md={12}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Image
                  src={imgSrc}
                  alt={`Gallery image ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />

                {index === 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Paragraph>
                      It was this freedom that led me to find the piano in a
                      serendipitous way. The melodies of this instrument seemed
                      to effortlessly merge with my breath, becoming a part of
                      me, without any need for pretense or display, but simply
                      an expression of my emotions and soul. I am also lucky to
                      have long, delicate hands - hands that any musician would
                      dream of. Thanks to that, I was able to easily reach the
                      twelfth (with my left hand) and eleventh (with my right
                      hand) octaves, without any strain. It is through these
                      hands that the connection between my heart and music is
                      created, allowing me to explore the depths and appreciate
                      the beauty of every musical note.
                    </Paragraph>
                  </motion.div>
                )}
              </motion.div>
            </Col>
          ))}
        </Row>
        <br />
        <br />

        {/* A Story with My Grandfather START*/}
        <Row gutter={[50, 50]} justify="center">
          <br />
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">A Story With My Grandfather</div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="space-around" align="middle">
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[8]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Paragraph>
              My grandfather used to say, “Quang is my little explorer.” When I
              was a child, he would often take me on his bicycle, leisurely
              riding through the tree-lined streets of Hanoi, especially along
              Phan Dinh Phung Street. I would always sit in front of him,
              curiously looking around. The long rows of green trees on both
              sides of the road made me feel like we were passing through
              castles in fairy tales. His deep, gentle voice never tired as he
              patiently answered all my endless questions. Sometimes, he would
              tell me stories about old Hanoi, blending them with his childhood
              memories, and transporting me to his own magical world.
            </Paragraph>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[7]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  But a severe illness named glaucoma took my grandfather’s
                  vision away. Since then, our rides along Phan Dinh Phung
                  Street have become a precious memory. He no longer accompanies
                  me to explore the city, but the beautiful stories he shared
                  with me remain vivid. The quiet presence of his absence
                  accompanies me as I walk through the busy streets, filled with
                  love and nostalgia.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />

        <Row gutter={[50, 50]} justify="space-around" align="middle">
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[9]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[11]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[10]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        <br />
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Now that I’m older and often walk through Hanoi’s streets
                  alone, I realize there is something unsettling about the city.
                  The streets are more crowded, narrower, and more chaotic than
                  I remember, especially with poorly planned projects. But what
                  saddens me most are the ordinary people - the ones who have
                  lost their homes to make way for shiny new buildings, leaving
                  a feeling of emptiness in the air. Every time I see that, I
                  can’t help but miss my grandfather and the peaceful journeys
                  we once had, which now live on only in my memories.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <br />
        {/* A Story with My Grandfather END*/}

        {/* Jealousy Of My Sister START*/}
        <Row gutter={[50, 50]} justify="center">
          <br />
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">Jealousy Of My Sister</div>
                <Paragraph>
                  When I was young, I always felt that my sister was like a
                  shining star in everyone’s eyes. She was intelligent,
                  academically successful, and skillful in everything she did.
                  As a child, I often felt a wave of jealousy whenever I heard
                  praise directed toward her. One day, while my sister was
                  sitting and painting, I approached her unnoticed, pretending
                  not to care but full of childish thoughts. I wasn’t talented
                  in art, but that day I thought that if I ruined her painting,
                  I might get some attention. So, I took a brush and messily
                  scribbled all over her artwork. When she noticed, she just
                  looked at me with sad eyes. She didn’t yell or scold me, but
                  simply sat in silence for a while, then quietly cut up the
                  ruined painting.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="space-around" align="middle">
          {imageS3.map((imgSrc, index) => (
            <Col key={index} xs={24} md={12}>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Image
                  src={imgSrc}
                  alt={`Gallery image ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </motion.div>
            </Col>
          ))}
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  The sense of victory I initially felt was immediately replaced
                  by guilt. My sister didn’t tell my parents, nor did she blame
                  me for ruining her work. It was then I realized how much she
                  truly cared for me, always patiently accepting my childish
                  mischief. In fact, my sister wasn’t just skillful, but she was
                  also always gentle, and kind-hearted, protecting me from my
                  own mistakes.
                </Paragraph>
                <Paragraph>
                  Looking back, I now understand that my jealousy was just a
                  natural part and parcle of growing up. I wasn’t jealous
                  because I disliked her; I was actually seeking the love and
                  attention of people around me. Over time, I have come to
                  realize that sibling relationships aren’t about competing for
                  praise, but rather about love, understanding, and forgiveness.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        {/* Jealousy Of My Sister  END*/}

        {/* link menu */}

        <motion.div
          style={{
            backgroundColor: "#8B0000",
            height: "4px",
            width: "100%",
            originX: 0,
          }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
        />
        <br />

        <Row gutter={[50, 50]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <motion.span
              className="custom-header"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <b>Development Process</b>
            </motion.span>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">
                  Spontaneity On The Piano Keys…
                </div>
                <Paragraph>
                  Do you believe that a passion for music can strike you at any
                  moment? My journey with the piano began unexpectedly. Music
                  had always filled our home, as my older sister had been
                  learning piano for a long time. Her melodies, flowing through
                  the house, quietly seeped into my soul in a very natural way,
                  and perhaps it was that gentle exposure that set me on my own
                  path.....
                </Paragraph>
              </motion.div>
              <div style={{ textAlign: "end" }}>
                <Button color="danger" variant="solid">
                  <NavLink to="/process"> Enter Now</NavLink>
                </Button>
              </div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Ubw37ooclBQ?si=rcvnBagwUuJ-OoPb"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
        <br />

        <motion.div
          style={{
            backgroundColor: "#8B0000",
            height: "4px",
            width: "100%",
            originX: 0,
          }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
        />
        <br />
        <br />
        <br />

        <Row gutter={[50, 50]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <motion.span
              className="custom-header"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <b>Achievements</b>
            </motion.span>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">Symphony Of Victory</div>
                <Paragraph>
                  Throughout my journey of studying and practicing the piano, I
                  have had the opportunity to participate in many domestic and
                  international music competitions, achieving impressive
                  results. These awards are not only the result of my efforts
                  but also a testament to my deep passion for music and the
                  dedicated guidance of teachers who have supported my
                  continuous growth......
                </Paragraph>
              </motion.div>
              <div style={{ textAlign: "end" }}>
                <Button color="danger" variant="solid">
                  <NavLink to="/achievements"> Enter Now</NavLink>
                </Button>
              </div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xVYIYUR-dtU?si=0i8TYQV_01p5t53i"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
        <br />
      </Content>
    </Layout>
  );
};

export default Home;
