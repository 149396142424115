import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Modal, Image, Card, Typography } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import ImagesProcess from "../images/ImagesProcess.js";
import demo from "../assets/process/demo.jpg";

import "./Process.css";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const linkVideoID = [
  {
    key: 1,
    title: "Nguyen Tri Quang - Daily Piano Practice",
    value: "Ubw37ooclBQ",
  },
  {
    key: 2,
    title: "Nguyen Tri Quang - Daily practice 2",
    value: "47xecgTtPog",
  },
  {
    key: 3,
    title: "Nguyen Tri Quang - Daily practice 3",
    value: "JCOs12-97Pw",
  },
  {
    key: 4,
    title: "Nguyen Tri Quang - Daily practice 4",
    value: "szr_6kkxFfM",
  },
  {
    key: 5,
    title: "Nguyen Tri Quang - Daily practice 5",
    value: "7hROa4xh3es",
  },
  {
    key: 6,
    title: "Nguyen Tri Quang - Daily practice 6",
    value: "WRTFhrBWT0M",
  },

  {
    key: 7,
    title: "Nguyen Tri Quang - Daily practice 7",
    value: "iHYt2BbB0dA",
  },

  {
    key: 8,
    title: "Nguyen Tri Quang - Daily practice 8",
    value: "hYX7B2wFNE0",
  },

  {
    key: 9,
    title: "Nguyen Tri Quang - Daily practice 9",
    value: "MvS7zyv5Ekc",
  },
  {
    key: 10,
    title: "Nguyen Tri Quang - Daily practice 9",
    value: "NXQA4OAMcLU",
  },
  {
    key: 11,
    title: "Trí Quang - piano practice",
    value: "UgaDoCOZL-8",
  },
  {
    key: 12,
    title: "Nguyen Tri Quang - Daily practice 9",
    value: "ISWrFWWKMZM",
  },
];

const Process = () => {
  const imageList = Object.values(ImagesProcess);
  const imageS1 = imageList.slice(0, 4);

  return (
    <Layout
      style={{
        height: "100%",
        background: "none",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Content
        style={{ padding: "40px 0px", overflow: "hidden" }}
        className="process"
      >
        <Row gutter={[50, 50]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <motion.span
              className="custom-header"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <b>CHAPTER 1: DEVELOPMENT PROCESS</b>
            </motion.span>
          </Col>
        </Row>
        <br />
        {/* Spontaneity on the Piano Keys…   START  */}

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                overflow: "hidden",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">
                  Spontaneity On The Piano Keys…
                </div>
                <Paragraph>
                  Do you believe that a passion for music can strike you at any
                  moment? My journey with the piano began unexpectedly. Music
                  had always filled our home, as my older sister had been
                  learning piano for a long time. Her melodies, flowing through
                  the house, quietly seeped into my soul in a very natural way,
                  and perhaps it was that gentle exposure that set me on my own
                  path.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Video */}
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[0].value}`}
                  title={linkVideoID[0].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[1].value}`}
                  title={linkVideoID[1].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                paddingTop: "50px",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  When I was almost 4 years old, I followed my sister to her
                  piano lesson. While my sister and my mother were chatting with
                  her teacher, I stood next to the piano in the living room,
                  curious and reaching out to touch the keys. Seeing this, her
                  teacher, we later called her me Mai, patted on my shoulder and
                  encouraged me, saying, “Why don’t you try sitting down and
                  playing a little?” Without thinking much, I sat down and, as
                  if naturally connected to the keys, my fingers started gliding
                  across them. Though the sounds weren’t yet fully formed
                  melodies, there was something natural and comfortable in that
                  moment.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Video */}
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[2].value}`}
                  title={linkVideoID[2].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[3].value}`}
                  title={linkVideoID[3].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Me Mai was surprised and remarked that I had a natural musical
                  instinct. My mother noticed this as well and decided to enroll
                  me in piano lessons soon after. It wasn’t a planned decision,
                  but rather one born from that serendipitous moment. And so, my
                  journey with music began — softly and naturally, as if it was
                  always meant to be.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Video */}
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[4].value}`}
                  title={linkVideoID[4].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[5].value}`}
                  title={linkVideoID[5].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        {/* Spontaneity on the Piano Keys…   END      */}

        {/* The Story with My Sister  START      */}
        <br />

        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">The Story With My Sister</div>
              </motion.div>
            </div>
          </Col>
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Since I was little, I have been accustomed to the sound of the
                  piano resonating throughout the house, played by none other
                  than my sister. She is the epitome of the “ideal child”, a
                  role model that anyone would admire. She simultaneously
                  studied at Hanoi - Amsterdam High School for the Gifted and
                  the Vietnam National Academy of Music in Hanoi, two
                  prestigious schools that many ever dream of attending. Not
                  only that, but she was also the valedictorian, both at the
                  entrance and graduation of the Academy of Music, an
                  achievement that not many can claim. Even more impressively,
                  she was accepted into Yale University (Yale class of 2022), a
                  prestigious school whose name alone inspires awe. To many
                  people, my sister is the perfect role model, someone everyone
                  aspires to be like.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageS1[1]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        <br />
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  But to me, at that time, I was just a simple and carefree boy.
                  My sister was both a role model I looked up to and an
                  unintentionally “formidable opponent” in music! Every time she
                  sat in front of the piano and played those wonderful pieces, I
                  would secretly “compete” with her. I would sit beside her,
                  listen, and feel a challenge within: “I can do that too!”
                  After each of her practice sessions, I would often sneak in to
                  sit at the piano, trying to play a few notes to see if I could
                  produce sounds similar to hers. There were times when I
                  believed that if I just tried a little harder, and practiced a
                  bit more, I could surpass her—becoming a better piano player
                  than she was.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <Row gutter={[50, 50]} justify="center">
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageS1[0]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageS1[2]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>{" "}
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Now that both my sister and I are grown, there are moments
                  when we sit together and talk, and I always confess that she
                  was the one who ignited the flame of music within me, even
                  though she may have never realized it or perhaps she could
                  think I was jealous of her. She was not only the one who went
                  ahead, a model older sister, but also the person who silently
                  and unconsciously shaped my musical path. As for me, despite
                  being carefree, innocent, and absolutely childish, always
                  thinking I was “competing” with her, the truth is that she was
                  the one who supported and fueled the passion within me, making
                  my fire of enthusiasm burn brighter each day.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        {/* The Story with My Sister  END      */}

        {/* Stepping onto the Path of Professional Piano Study START */}
        <Row gutter={[50, 16]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">
                  Stepping Onto The Path Of Professional Piano Study
                </div>
                <Paragraph>
                  In 2016, I took the entrance exam for the Vietnam National
                  Academy of Music’s formal program, following my passion for
                  the piano. Although I was nervous, I ranked second and became
                  one of the top students in the Piano Faculty. This marked not
                  only a milestone in my musical career but also opened doors to
                  opportunities I had never imagined.
                </Paragraph>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[10].value}`}
                  title={linkVideoID[10].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[11].value}`}
                  title={linkVideoID[11].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        {/* Stepping onto the Path of Professional Piano Study END */}
        <br />

        {/* The Teacher Who Inspired My Musical Journey START */}
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                paddingTop: "30px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title" style={{ textAlign: "center" }}>
                  The Teacher Who Inspired My Musical Journey
                </div>
              </motion.div>
            </div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} align="middle">
          <Col span={24} md={12}>
            <Paragraph>
              From my first days at the Academy, I was always among the top
              students. What made me feel most fortunate was being selected as a
              student of Professor Tran Thu Ha - a legendary figure in Vietnam’s
              piano community. She is a former director of the National Academy
              of Music in Hanoi and one of the most prestigious pianists, and
              pedagogists in Vietnam. She is not only a brilliant educator,
              guiding and inspiring generations of talented students, but also a
              deeply compassionate mentor who always conveys warmth and
              encouragement.
            </Paragraph>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <Image
                src={imageList[9]}
                alt={`Gallery image`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </motion.div>
          </Col>
          <Col span={24} md={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <Image
                src={imageList[10]}
                alt={`Gallery image`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </motion.div>
          </Col>
        </Row>
        <br />
        <Row gutter={[50, 50]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  Her teaching method is special; she never pressures her
                  students or makes them feel tense. Instead, she uses a gentle
                  approach, often referring to each student’s talents as “a full
                  moon,” helping us develop our abilities in the most natural
                  way until they fully shine. This is why my love for music has
                  always occupied my entire heart in such a pure and natural
                  manner.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Video */}
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[6].value}`}
                  title={linkVideoID[2].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
                paddingTop: "50px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[7].value}`}
                  title={linkVideoID[3].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>

        <br />
        <br />

        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  She is like my beloved, my dear grandmother to me, always
                  caring, loving, and understanding the pressures I must face,
                  while always being ready to listen and support me during
                  difficult times. She not only provided me with deep musical
                  knowledge but also helped me overcome the most challenging
                  periods of my learning and personal development.
                </Paragraph>
              </motion.div>
            </div>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[8].value}`}
                  title={linkVideoID[2].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        {/* The Teacher Who Inspired My Musical Journey END */}

        <br />
        <br />

        {/* Piano Performances
         */}
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">Piano Performances</div>
                <br />
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 20]} justify="center">
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  Duo Performer, Tryst 2023 - Melancolie
                </div>

                <br />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={imageList[12]}
                  alt={`Gallery image`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  Performer, “Healing Symphony” for social work at 108 Military
                  Hospital
                </div>
              </motion.div>
            </div>
          </Col>

          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={imageList[13]}
                  alt={`Gallery image`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </motion.div>
            </div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  Singer, Choir “Ella-Ever After”
                </div>

                <br />
              </motion.div>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[14]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[15]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={8}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[16]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>

          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-2">
                  Solo Performer, Maestoso Junior Concerto “Music for Happy
                  Smiles”{" "}
                </div>

                <br />
              </motion.div>
            </div>
          </Col>
        </Row>
        <Row gutter={[50, 20]} align="middle">
          <Col span={24} md={8}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={imageList[17]}
                  alt={`Gallery image`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={imageList[18]}
                  alt={`Gallery image`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </motion.div>
            </div>
          </Col>
          <Col span={24} md={8}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={imageList[19]}
                  alt={`Gallery image`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </motion.div>
            </div>
          </Col>


          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
                paddingTop: "20px",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >

                <Paragraph>
                  Standing before a grand orchestra for the very first time,
                  I felt emotions that were hard to put into words—a hint of nervousness mixed
                  with a swell of pride and joy that filled me completely.
                  The bright stage lights surrounded me, casting a glow over each piano key beneath my fingers.
                  It was a magical moment, the excitement and a bit of anxiety blending into pure happiness as
                  I prepared to play, feeling like everything had led me right here to this unforgettable experience.
                </Paragraph>

              </motion.div>
            </div>
          </Col>

          {/* video thêm */}

          <Col span={24}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="600px"
                  src="https://www.youtube.com/embed/e-6DsfptnTo?si=8zKtHTNSuweRyZKR" title="Beethoven Piano Concerto No. 3 in C Minor Op. 37 - Allegro con brio"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>

        </Row>


        {/* Piano Performances
         */}

        <br />
        <br />

        {/* CHALLENGES AND DIFFICULTIES IN PURSUING PASSION
         */}
        <br />
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">
                  Challenges And Difficulties In Pursuing Passion
                </div>
                <br />
                <div className="custom-title-1">The Sonata Of Choice</div>
                <Paragraph>
                  Alongside the moments of shining and joy when reaping the
                  sweet fruits of international awards, I also faced numerous
                  difficulties and challenges.
                </Paragraph>
                <Paragraph>
                  In 9th grade, when preparing for the entrance exams to 10th
                  grade, I began to truly feel the mounting stress and pressure.
                  Studying at two schools simultaneously – a top public school
                  and the National Academy of Music - with two completely
                  different curriculums made me more exhausted than ever. At the
                  public school, I had to focus on academic subjects like Math,
                  Literature, and English to prepare for the upcoming intense
                  exams. Meanwhile, at the Academy of Music, I had to dedicate
                  several hours every day to piano practice, completing
                  assignments on music theory, aural skills, and piano technique
                  and more. Balancing these two programs wasn’t easy; I often
                  had to stay up late and wake up early, breaking my time into
                  small chunks to complete both sets of tasks.
                </Paragraph>

                <Paragraph>
                  There were mornings when my mind was spinning, thinking about
                  the pile of unfinished assignments from public school, while
                  in the afternoon, I had to mentally prepare for a long piano
                  practice session. The fatigue kept building up, making me want
                  to give up many times. The piano practice sessions, which I
                  should have passionately enjoyed, instead became moments full
                  of pressure, as I struggled to perfect the difficult musical
                  passages.
                </Paragraph>

                <Paragraph>
                  I felt like I was stuck in an endless cycle, with overlapping
                  schedules and no time to breathe. In moments of exhaustion and
                  despair, I turned to my mother and exclaimed, “Mom, I want to
                  take a gap year from music school; I can’t continue anymore.”
                </Paragraph>

                <Paragraph>
                  I truly believed that a year off would help me escape this
                  exhausting cycle. I just wanted time to focus on either
                  preparing for the public school entrance exam or, conversely,
                  to devote myself entirely to music. I felt like I couldn’t do
                  both well at the same time. But my mom didn’t scold or
                  immediately reject the idea. She calmly looked at me for a
                  moment and said, “Dear, I understand that you’re under a lot
                  of pressure. But I’ve seen many friends take a gap year and
                  never return to playing the piano. Music is something that can
                  easily be left behind if you stop in the middle. Are you sure
                  you want to pause?”
                </Paragraph>

                <Paragraph>
                  Those words left me speechless. I had been attached to music
                  since I was young, and I had spent so much time and effort
                  pursuing it. At that moment, it was hard to say whether my
                  initial motivation was to “compete” with my sister or if I had
                  unconsciously fallen in love with the piano along the way.
                  Could I really quit halfway just because of this temporary
                  fatigue? My mother’s encouragement, along with my teacher’s,
                  made me reflect even more. My beloved teacher gently said,
                  “This journey is tough, but if you can get through it, you
                  will become much stronger. Not everyone has the opportunity to
                  pursue both academics and their passion like you do. Be
                  patient for just a little longer.”
                </Paragraph>

                <Paragraph>
                  That was the only time I seriously considered taking a break
                  from music. But thanks to the encouragement from my mother, my
                  teacher, and the dertemination I had inside, I decided to keep
                  going. Day by day, I gradually learned how to manage my time,
                  reduce the pressure, and rediscover the joy in every note.
                  Looking back, that was the most challenging period in my
                  academic journey, but it was also when I learned the valuable
                  lesson of perseverance and never giving up easily.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[4]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[5]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        {/* CHALLENGES AND DIFFICULTIES IN PURSUING PASSION
         */}

        {/*When Childhood Was Tied to Piano Keys

         */}
        <br />
        <br />

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title-1">
                  When Childhood Was Tied To Piano Keys
                </div>
                <Paragraph>
                  Learning to play the piano wasn’t like what I had initially
                  imagined. Behind the soothing melodies lies a challenging
                  journey that requires great perseverance and sacrifice. While
                  my peers enjoyed their childhood with outings, swimming, or
                  participating in fun summer activities, I was tied to the
                  piano, classical music, and endless hours of practice.
                </Paragraph>
                <Paragraph>
                  Studying at the conservatory typically involves two semesters,
                  but for both my sister and me, summer seemed like a third
                  semester. I recall the summers when my mother and sister would
                  go abroad for music studies and competitions, and now it’s my
                  turn. This meant that summer vacations almost didn’t exist for
                  me. While my friends excitedly shared stories of their trips
                  and sun-filled days, I would spend more time practicing the
                  piano and traveling for competitions abroad. I finally
                  understood why my sister once confided in my mother: “Why
                  don’t I have a childhood like other kids? Why is my summer
                  only filled with piano keys and assignments?”{" "}
                </Paragraph>

                <Paragraph>
                  There were times when I wondered whether I would regret not
                  having proper summer vacations like my friends. But then,
                  every time my hands glide over the piano keys, the music that
                  flows reminds me that all these hardships are worth it. Music
                  is where I find true joy, where all the fatigue and worries
                  dissolve in each note. Whenever I complete a difficult piece
                  or successfully perform on stage, I know that those sacrifices
                  were not in vain.
                </Paragraph>

                <Paragraph>
                  Even though I lack the memories of summer trips or carefree
                  days with friends, in return, I have my own special memories
                  with the piano - memories that I am proud of and cherish. The
                  rigorous practice sessions taught me patience, discipline, and
                  determination. And most importantly, they helped me discover
                  my true passion - a place where every challenge becomes the
                  motivation for me to go further on my musical journey.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[11]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  There were times when I wondered whether I would regret not
                  having proper summer vacations like my friends. But then,
                  every time my hands glide over the piano keys, the music that
                  flows reminds me that all these hardships are worth it. Music
                  is where I find true joy, where all the fatigue and worries
                  dissolve in each note. Whenever I complete a difficult piece
                  or successfully perform on stage, I know that those sacrifices
                  were not in vain.
                </Paragraph>

                <Paragraph>
                  Even though I lack the memories of summer trips or carefree
                  days with friends, in return, I have my own special memories
                  with the piano - memories that I am proud of and cherish. The
                  rigorous practice sessions taught me patience, discipline, and
                  determination. And most importantly, they helped me discover
                  my true passion - a place where every challenge becomes the
                  motivation for me to go further on my musical journey.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          <Col span={24} md={12}>
            <div
              style={{
                textAlign: "center",
                background: "none",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <iframe
                  width="100%"
                  height="400px"
                  src={`https://www.youtube.com/embed/${linkVideoID[9].value}`}
                  title={linkVideoID[9].value}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        {/*When Childhood Was Tied to Piano Keys*/}

        {/*My Mother’s Embrace on My Musical Journey
         */}

        <br />
        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="custom-title">
                  My Mother’s Embrace On My Musical Journey
                </div>
                <Paragraph>
                  From the time I first placed my fingers on the piano keys, my
                  mother was always by my side. She did not expect me to become
                  an internationally renowned artist or to pursue music
                  professionally. She had studied both piano and violin herself,
                  but her desire for me was much simpler. She just wanted me to
                  learn piano to discover myself and for music to be a source of
                  spiritual support, helping me find peace in my soul.
                </Paragraph>
                <Paragraph>
                  The path of pursuing music has not always been easy, but I
                  never felt alone. During the exhausting days of practice, on
                  the sleepless nights worrying about upcoming performances, she
                  silently followed and cared for me. She never forced or
                  pressured me but always encouraged me to find joy and meaning
                  through every note of music.
                </Paragraph>

                <Paragraph>
                  Whenever I step onto the stage, I always feel a rush of
                  excitement and nerves. But I know that, somewhere in the
                  audience, her familiar presence is waiting, standing by. No
                  matter how the performance turns out, even if I make mistakes
                  or stumble, she is always the first to come to my side,
                  patting my shoulder to encourage me, just like my beloved
                  teacher. My mother never says much, but what she does speaks
                  volumes about the immense pride and happiness she feels. She
                  doesn’t just celebrate my success; she honors my courage to
                  face my fears and overcome challenges, even just by pressing
                  the piano keys.
                </Paragraph>

                <Paragraph>
                  I still remember when I played a piece that carried the melody
                  of the folk songs from the Mekong Delta at the Santa Cecilia
                  Piano International Competition 2019. My mother was
                  overwhelmed with emotion. I knew she was incredibly proud
                  because that moment of simple folk music from our homeland was
                  illuminated by the spotlight on the international stage,
                  contributing to the honor of introducing the beauty of
                  Vietnamese culture to friends from five continents.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[6]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>

          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[7]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          {/* Cột cho Văn bản */}
          <Col span={24} md={12}>
            <div
              style={{
                background: "none",
                color: "#000",
                textAlign: "justify",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paragraph>
                  In those international competitions, the things I carried with
                  me were not just music scores but also the love and warmth of
                  my mother. She always carefully prepared each Vietnamese meal
                  for me. Amid the stress and pressure, the meals she cooked
                  brought me comfort and peace. My mother worried about each
                  bite I took, arranging every detail meticulously, so that I
                  was always in my best state of mind. Without her, I wouldn’t
                  have been able to face those challenges with the same strength
                  and confidence.
                </Paragraph>

                <Paragraph>
                  Every small action of my mother, from packing meals backstage
                  to supporting me during faraway competitions, was a source of
                  encouragement and priceless motivation in my musical journey.
                  Those were the comforting arms my mother always offered, not
                  just in music but throughout every step of my life.
                </Paragraph>
              </motion.div>
            </div>
          </Col>
        </Row>

        <Row gutter={[50, 50]} justify="center">
          <Col xs={24} sm={12}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              whileHover={{ scale: 1.05 }}
              style={{
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Image
                src={imageList[8]}
                alt={`Gallery image`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </motion.div>
          </Col>
        </Row>
        {/*My Mother’s Embrace on My Musical Journey
         */}
      </Content>
    </Layout>
  );
};

export default Process;
