import p1 from "../assets/achievement/a1.jpg";
import p2 from "../assets/achievement/a2.jpg";
import p3 from "../assets/achievement/a3.JPG";
import p4 from "../assets/achievement/a4.JPG";
import p5 from "../assets/achievement/a5.jpg";
import p6 from "../assets/achievement/a6.jpg";
import p7 from "../assets/achievement/a7.jpg";
import p8 from "../assets/achievement/a8.jpg";
import p9 from "../assets/achievement/a9.JPG";
import p10 from "../assets/achievement/a10.JPG";
import p11 from "../assets/achievement/a11.JPG";

import p12 from "../assets/achievement/a12.jpg";
import p13 from "../assets/achievement/a13.jpg";
import p14 from "../assets/achievement/a14.jpg";
import p15 from "../assets/achievement/a15.jpg";
import p16 from "../assets/achievement/a16.jpg";
import p17 from "../assets/achievement/a17.jpg";
import p18 from "../assets/achievement/a18.jpg";

import p19 from "../assets/achievement/a19.jpg";

import p20 from "../assets/achievement/a20.jpg";
import p21 from "../assets/achievement/a21.jpg";
import p22 from "../assets/achievement/a22.jpg";
import p23 from "../assets/achievement/a23.jpg";
import p24 from "../assets/achievement/a24.jpg";
import p25 from "../assets/achievement/a25.jpg";
import p26 from "../assets/achievement/a26.jpg";
import p27 from "../assets/achievement/a27.jpg";
import p28 from "../assets/achievement/a28.jpg";
import p29 from "../assets/achievement/a29.jpg";
import p30 from "../assets/achievement/a30.jpg";
import p31 from "../assets/achievement/a31.JPG";
import p32 from "../assets/achievement/a32.JPG";
import p33 from "../assets/achievement/a33.JPG";

const ImagesAchievement = {
  // 2017: Competitions in Spain and France
  p1,
  p2,
  p3,
  p4,
  //Recipient of Toyota Scholarship for Young Vietnamese Music Talents (2017)
  p5,
  p6,
  p7,
  p8,
  // s2
  p9,
  p10,
  p11,
  // slide
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  p22,
  p23,
  p24,
  p25,
  p26,
  p27,
  p28,
  p29,
  p30,
  p31,
  p32,
  p33,
};

export default ImagesAchievement;
