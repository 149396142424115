import p1 from "../assets/achievement/a12.jpg";
import p2 from "../assets/achievement/a13.jpg";
import p3 from "../assets/achievement/a14.jpg";
import p4 from "../assets/achievement/a15.jpg";
import p5 from "../assets/achievement/a16.jpg";
import p6 from "../assets/achievement/a18.jpg";

const ImagesAchievementKorea = {
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
};

export default ImagesAchievementKorea;
