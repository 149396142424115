import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppFooter from "./components/AppFooter";
import Header from "./components/Header";
import AppRoutes from "./routes";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // Không cần render gì cả
}

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Cuộn lên đầu trang khi thay đổi đường dẫn */}
      <div style={styles.container}>
        <Header />
        <div style={styles.body}>
          <AppRoutes />
        </div>
        <AppFooter />
      </div>
    </Router>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  body: {
    margin: "auto",
    width: "100%",
    maxWidth: "1200px",
    paddingTop: "80px",
  },
};

export default App;
