// src/routes/index.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Process from "../pages/Process";
import Achievements from "../pages/Achievements";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/process" element={<Process />} />
      <Route path="/achievements" element={<Achievements />} />
    </Routes>
  );
};

export default AppRoutes;
