// src/components/Footer.tsx
import React from 'react';
import { Layout, Row, Col } from 'antd';
import { MailOutlined, EnvironmentOutlined } from '@ant-design/icons';
const { Footer } = Layout;

const styles = {
  footer: {
    backgroundColor: '#8B0000',
    padding: '20px 0',
    textAlign: 'center',
  },
  icon: {
    fontSize: '20px',
    color: '#1890ff',
    marginRight: '10px',
    color: '#fff'
  },
  info: {
    fontSize: '16px',
    color: '#333',
    color: '#fff'

  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
};
const AppFooter = () => {
  return (
    <Footer style={styles.footer}>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <div style={styles.info}>
            <MailOutlined style={styles.icon} />
            Email: <a href="mailto:triquangnguyen2652007@gmail.com" style={styles.link}>triquangnguyen2652007@gmail.com</a>
          </div>
        </Col>
        <Col span={24}>
          <div style={styles.info}>
            <EnvironmentOutlined style={styles.icon} />
            Address: VIMECO CT4 Nguyen Chanh, Hanoi, Vietnam
          </div>
        </Col>
      </Row>
    </Footer>
  );
};



export default AppFooter;
